import React from 'react';
import { Link } from 'react-router-dom';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { bannerVideo, bannerImage, banner1, banner2 } from './Home.module.scss';
import session from './_videos/session.mp4';
import logo from './_images/logo.png';
import headshot from './_images/headshot.jpg';
import robyn from './_images/robyn.png';
import stacey from './_images/stacey.png';
import jazmyn from './_images/jazmyn.png';

function Home() {
    return (
        <>
            <div className="position-relative overflow-hidden">
                <ResponsiveEmbed aspectRatio="4by3" className={`${bannerVideo} position-absolute`}>
                    <video autoPlay muted loop>
                        <source src={session} type="video/mp4" />
                    </video>
                </ResponsiveEmbed>

                <Container>
                    <Row className="justify-content-center">
                        <Col xs="10" sm="9" md="7" lg="5" className="p-3 p-lg-5 text-center">
                            <Image fluid src={logo} />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="bg-purple py-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="text-center text-light" lg="12" xl="10">
                            <h1>Experience One-on-One Coaching Sessions tailored to meet individual needs!</h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={`${bannerImage} ${banner1} text-dark py-3 py-md-5`}>
                <Container>
                    <Row>
                        <Col xs="12" md="6">
                            <h2 className="h3">Are your children...</h2>
                            <ul>
                                <li>Arguing about everything</li>
                                <li>Lacking &ldquo;common sense.&rdquo;</li>
                                <li>Struggling with organization and time management</li>
                            </ul>
                        </Col>
                        <Col xs="12" md="6">
                            <h2 className="h3">Want your child to...</h2>
                            <ul>
                                <li>Advocate for themselves</li>
                                <li>Self-monitor emotions and behavior</li>
                                <li>Become more independent and confident</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <hr/>

                    <Row>
                        <Col xs="12" lg="10">
                            <h2 className="h3">If you have said any of these things, LULA is here to guide you!</h2>
                            <p className="lead">Our academic coaches, together with you, will help your child master Executive Function and Life Skills!</p>
                        </Col>
                        <Col xs="12" lg="10">
                            <p>We meet with your child one-on-one in virtual sessions to develop strong academic strategies and positive life habits. Our goal is to help students evaluate their current learning habits and improve systems of best practice.</p>
                            <Button variant="green" size="lg" href="https://forms.gle/mf3U1oWjf9GeTtCn8" target="_blank">Take our quick Needs Assessment</Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="bg-blue text-light py-3 py-md-5">
                <Container className="text-center text-md-left">
                    <h2 className="h3">Meet Your Head Coach</h2>

                    <hr className="border-light" />

                    <Row className="justify-content-center justify-content-md-start">
                        <Col xs="10" sm="8" md="5" lg="5" xl="4">
                            <Image src={headshot} fluid thumbnail />
                        </Col>
                        <Col xs="12" md="7" xl="6">
                            <p className="lead font-weight-bold">Mrs. Andrea Johnson, M.ED</p>
                            <p>Since 2002, Mrs. Johnson has had the honor of working with over 2,500 families and students of all ages in a variety of content areas.</p>
                            <p>From theater director, speech teacher, working with special education students, and AVID (Advancement via Individual Determination), Mrs. Johnson has a passion for fostering a growth mindset and inspiring students.</p>
                            <p>Andrea Johnson holds a Bachelor's degree in Theatre Performance and Theatre Education from the University of Northern Iowa, and a Master's degree in Curriculum and Instruction from the University of North Texas. She also has a principal certification from Lamar University.</p>
                            <Button variant="green" size="lg" href="http://calendly.com/mylulacoach" target="_blank" rel="noopener noreferrer">Meet with Andrea and learn how LULA can help</Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="10" className="py-3"><hr className="border-light" /></Col>
                        <Col xs="12" sm="6" lg="4" className="text-center">
                            <Image fluid src={robyn} roundedCircle thumbnail className="w-50" />
                            <blockquote className="blockquote">
                                <p className="mb-0">&ldquo;[Mrs. Johnson] considers the best approach to meet the unique needs of each person.&rdquo;</p>
                                <footer className="blockquote-footer text-yellow">Robyn Delahunt, colleague</footer>
                            </blockquote>
                        </Col>
                        <Col xs="12" sm="6" lg="4" className="text-center">
                            <Image fluid src={stacey} roundedCircle thumbnail className="w-50" />
                            <blockquote className="blockquote">
                                <p className="mb-0">&ldquo;[Mrs. Johnson] truly has a gift in communicating with students and bringing out the best in each of them.</p>
                                <footer className="blockquote-footer text-yellow">Stacey Pearlman, parent</footer>
                            </blockquote>
                        </Col>
                        <Col xs="12" sm="6" lg="4" className="text-center">
                            <Image fluid src={jazmyn} roundedCircle thumbnail className="w-50" />
                            <blockquote className="blockquote">
                                <p className="mb-0">&ldquo;Thanks to Mrs. Johnson, I believe in myself and I know I can do anything I set my mind to!&rdquo;</p>
                                <footer className="blockquote-footer text-yellow">Jazmyn Tullos, student</footer>
                            </blockquote>
                        </Col>
                        <Col xs="12" className="text-center">
                            <Button as={Link} variant="green" size="lg" href="/testimonials" to="/testimonials">View more testimonials</Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={`${bannerImage} ${banner2} text-dark py-3 py-md-5`}>
                <Container>
                    <Row>
                        <Col xs="12" md="8">
                            <h2 className="h3">Tuition Plans</h2>

                            <Table>
                                <tbody>
                                    <tr>
                                        <td>4 Sessions</td>
                                        <td>$240 / month</td>
                                    </tr>
                                    <tr>
                                        <td>6 Sessions</td>
                                        <td>$300 / month</td>
                                    </tr>
                                    <tr>
                                        <td>8 Sessions</td>
                                        <td>$360 / month</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <p className="font-weight-bold mt-3">FREE personal needs assessment with each new monthly membership.</p>
                            <p className="font-weight-bold">Cancel at any time!</p>

                            <Button variant="green" size="lg" href="https://forms.gle/w8zbsv8eZuKvGXtR9" target="_blank" rel="noopener noreferrer">Join LULA Today!</Button>

                            <hr/>

                            <h3 className="h4">Plans include:</h3>
                            <ul>
                                <li>Personalized curriculum to individual needs and goals</li>
                                <li>One-on-one sessions</li>
                                <li>Flexible scheduling</li>
                                <li>Convenience of virtual learning</li>
                                <li>Increase in student accountability, adaptability, and lifelong skills</li>
                                <li>Increase in student confidence, awareness, and self-advocacy skills</li>
                                <li>End of month parent consultation and progress review</li>
                            </ul>

                            <hr/>

                            <h3 className="h4">Still have questions?</h3>
                            <Button as={Link} variant="blue" size="lg" href="/faqs" to="/faqs">Read our frequently asked questions</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Home;
