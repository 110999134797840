import React from 'react';
import Container from 'react-bootstrap/Container';
import { banner } from './Testimonials.module.scss';

function Testimonials() {
    return (
        <>
            <div className={banner}>
                <Container className="py-3 py-sm-4 py-md-5">
                    <h1 className="display-1 text-center text-md-left py-3 py-sm-4 py-md-5">Testimonials</h1>
                </Container>
            </div>

            <Container className="py-5">
                <blockquote className="blockquote">
                    <h3>I have known Andrea Johnson as a teacher, colleague, and friend...</h3>
                    <p className="mb-0 small">...and in all capacities of life, she is passionate and dedicated to the betterment of all people. She is an innovative thinker who is willing to adapt and adjust to those she is supporting in order to help them grasp needed concepts that will enable them to grow as individuals. She is not only a creative problem solver, but a reflective educator who considers the best approach to meet the unique needs of each person she works with. She brings excitement to the most mundane academic activities because she truly understands the needs of all learners and she is a master at connecting new information to previously learned content in order to build skills sets that allow for generalization of information in a variety of settings.</p>
                    <footer className="blockquote-footer">Robyn Delahunt, PhD, Editor and Publisher of <cite>L'educateur Moderne Online Magazine</cite></footer>
                </blockquote>

                <hr/>

                <blockquote className="blockquote">
                    <h3>Adventurous, ambitious, compassionate, diligent, generous, sincere and witty...</h3>
                    <p className="mb-0 small">...are just SOME of the many words I could use to describe Mrs. Johnson. Mrs. Johnson came into my life during my sophomore year of high school (2015). She is, without a doubt, the most amazing teacher I have ever had in my 13 years of public schooling. She opened her classroom doors for me an hour and a half before school started every single morning for me to have a quiet place to study, for me come into to talk when I needed guidance, or just to have coffee with and hang out. She stood up for me in every situation and continuously took time out of her busy schedule to help not only me, but all of her students one on one with classes we struggled in, and 4 years later, I can genuinely say that she has become a mentor, a role model, and a huge supporter in my everyday life, and truly has helped in shaping me into the person I am today, and for that I am eternally grateful.</p>
                    <footer className="blockquote-footer">Melissa Mobilia, former student</footer>
                </blockquote>

                <hr/>

                <blockquote className="blockquote">
                    <h3>Mrs. Johnson was my high school theatre teacher, but more than that...</h3>
                    <p className="mb-0 small">...she was my friend and mentor. I never felt like another name on a roster that she was required to reach. I always felt like I could be myself around her, and that no question was too dumb to ask or wonder about. Mrs. Johnson is one of the reasons that I chose to pursue a career in theatre, but more than that, she made me feel like I could do anything! I spent many lunch periods in her classroom because I felt more comfortable in the space that she created than in the school cafeteria. She constantly helped me get over my fear of not being good enough by placing me in positions of leadership backside, letting me do the morning announcements, and helping me with my resume to get into the next theatre program. I would not be the confident, outgoing person that I am today without her encouragement and guidance. I still think of her as a good friend and mentor, even now that I have graduated from college.</p>
                    <footer className="blockquote-footer">Breanne Finneburgh, former student</footer>
                </blockquote>

                <hr/>

                <blockquote className="blockquote">
                    <h3>My daughter and I had the pleasure of traveling to Costa Rica with Mrs. Johnson this past summer...</h3>
                    <p className="mb-0 small">...through the Educational First program. My daughter has had Mrs. Johnson as her teacher for the past 2 years and has always told me that she is by far her favorite teacher yet. I didn’t realize what a gem Mrs. Johnson truly is until I joined as a chaperone for the summer travel student trip in 2019. Every single student that went with our group showed such admiration and respect for Mrs. Johnson. She truly has a gift in communicating with the students and bringing out the best in each of them. I cannot say enough good things. She pours her heart into everything she does and truly cares about every single student as if they were her family. Thank you Mrs. Johnson for pushing our daughter and for being amazing!</p>
                    <footer className="blockquote-footer">Stacey Pearlman, parent</footer>
                </blockquote>

                <hr/>

                <blockquote className="blockquote">
                    <h3>I was fortunate enough to have Andrea as a Sunday school teacher...</h3>
                    <p className="mb-0 small">...during my high school years and I was also able to work with her when I was working as an AVID (Advancement via Individual Determination) tutor in our local school district. Through Andrea’s personalized lessons and conversations during Sunday school, I was able to learn more about navigating real life situations. We discussed relationships, financials, college life and all the pressures associated with that. Through her, I was able to become more prepared for the transition into college and have a better understanding of what it would be like. She was such a positive influence, realistic and straight forward (which is very helpful) and an extremely caring, hard worker. From working with her in AVID, I was able to see firsthand how she has the student’s best interest in mind and is always an advocate for the student. I have really enjoyed all of my time with her and know that she will make a positive impact with all of her LULA students!</p>
                    <footer className="blockquote-footer">Rebekah Jacobsen, former student</footer>
                </blockquote>

                <hr/>

                <blockquote className="blockquote">
                    <h3>Having worked with Andrea for several years...</h3>
                    <p className="mb-0 small">...I know she is very dedicated to the success of all of her students. Andrea was the epitome of organization, motivation, and mastery. She was able to encourage all of her theatre students and those she was a personally coached, to reach their full potential and never just settle for a good performance but have a GREAT performance. For UIL One Act Play competition, Andrea staged a production of Children of a Lesser God. This production required her to work with very active, verbal students and mold them into extraordinary nonverbal actors using ASL to communicate. She went the extra step and individually instructed each Actor in ASL so the production would be authentic. The play was mesmerizing and the students truly mastered their roles. During competition, one judge remarked that the signing was not realistic. Andrea helped her students cops with this criticism and rise above it, taking pride in their performances. Andrea can truly motivate students to succeed!</p>
                    <footer className="blockquote-footer">Pamela Ballow, retired public school educator and college-level instructor</footer>
                </blockquote>

                <hr/>

                <blockquote className="blockquote">
                    <h3>I am very grateful to have Mrs. Johnson as part of my life...</h3>
                    <p className="mb-0 small">She was the individual in my life to make me realize that I can go to college and study my passion. Theater has always been my passion, but I never thought in a million years I could get a degree in it... until meeting Mrs. Johnson. She encouraged me even though I felt like I wasn’t as smart as the other kids in class. Nevertheless, she would always encourage me, hold me accountable, and go out of her way to help me with any problems that happened to pop up in life. Whether if it was financial issues when having to figure out how to pay for show fees at the time, or passing my classes for that grading period so that I could remain eligible to be a part of the performance- she was there for it all! Mrs. Johnson is an amazing woman, a strong fighter, and a passionate educator. She truly has impacted my life forever, and I am very grateful. Thanks to her I’m going into my third year of college at Sam Houston State University with a major in Theatre with an emphasis in acting and directing. A few years ago I didn’t think any of this would’ve been possible, but thanks to Mrs. Johnson I believe in myself and I know that with hard work and dedication, I can do anything I set my mind to!</p>
                    <footer className="blockquote-footer">Jazymn Tullos, former student</footer>
                </blockquote>

                <hr/>

                 <blockquote className="blockquote">
                    <h3>Mrs. Johnson is adaptable, intelligent, and above all caring.</h3>
                    <p className="mb-0 small">Mrs. Johnson's class was far from being the dull, sterile drudgery most of my school day felt like. I was lucky to learn from her!</p>
                    <footer className="blockquote-footer">Kent Van Dover, former student</footer>
                </blockquote>
            </Container>
        </>
    );
}

export default Testimonials;
