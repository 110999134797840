import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import mark from './_images/mark.png';

function Navigation() {
    const location = useLocation();

    return (
        <div className="bg-purple">
            <Container>
                <Navbar variant="dark">
                    <Navbar.Brand as={Link} href="/" to="/"><Image src={mark} width="18" /></Navbar.Brand>
                    <Nav activeKey={location.pathname}>
                        <Nav.Link as={Link} href="/testimonials" to="/testimonials">Testimonials</Nav.Link>
                        <Nav.Link as={Link} href="/faqs" to="/faqs">FAQs</Nav.Link>
                    </Nav>
                </Navbar>
            </Container>
        </div>
    );
}

export default Navigation;
