import React from 'react';
import Container from 'react-bootstrap/Container';
import { banner } from './FAQs.module.scss';

function FAQs() {
    return (
        <>
            <div className={banner}>
                <Container className="py-3 py-sm-4 py-md-5">
                    <h1 className="display-1 text-center text-md-left py-3 py-sm-4 py-md-5">Frequently Asked Questions</h1>
                </Container>
            </div>

            <Container className="py-5">
                <h3>How long is each coaching session?</h3>
                <p>Traditional LULA sessions run 40 minutes in length. Special Needs Academy Sessions are 25 minutes in length and will focus on the Builder’s School Curriculum.</p>

                <h3>How often do we meet?</h3>
                <p>Generally, students schedule on a once-a-week or twice-a-week basis.</p>

                <h3>Do coaching sessions always meet in person?</h3>
                <p>Yes! Coaching sessions are always “in person” and one-to-one. All sessions take place virtually! Our Level Up Learning Academy (LULA) learners utilize technology and other 21st century skills and enjoy flexible scheduling. LULA parents appreciate the ease and convenience of specialized curriculum and instruction tailored specifically for individual needs, without adding more travel time or time away from home!</p>

                <h3>Are other students in my coaching sessions with me?</h3>
                <p>No. Through academic coaching, students will experience the benefit of one-to-one, undivided attention with a coach. Unlike the traditional academic setting of 25-30 students and only one primary educator, the coaching environment is more personalized to the individual needs and learning styles of each student. LULA students are able to learn and grow at their own pace and through a specifically designed and tailored curriculum. Many students find that they are able to grow in confidence and embrace learning at a much higher level in this personalized environment.</p>

                <h3>How will you know what I need to learn or which curriculum would fit me the best?</h3>
                <p>All new students receive a FREE personal needs assessment to determine which curriculum would best fit individual needs. During this short assessment, parents and students will share any gaps in current learning as evidenced at home or within the traditional academic setting to address specific needs or areas of growth.</p>

                <h3>Do you serve students with learning differences?</h3>
                <p>Yes! Your Academic Coach is Special Education and ESL certified and has a passion for working with all students. Your Coach will work with parent/guardian to determine individual needs and create a “game plan” to pinpoint and address individual areas of academic and personal need.</p>

                <h3>Do you have a Referral Program?</h3>
                <p>For each LULA referral who enrolls as a new (monthly session) student, you will earn One FREE session. This is a hassle-free session for parents as your LULA learner will be the one to evaluate calendar and commitments and set up this bonus session!</p>
            </Container>
        </>
    );
}

export default FAQs;
