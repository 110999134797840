import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function App() {
    return (
        <div className="bg-pink text-light py-4">
            <Container className="font-weight-bold small">
                <Row className="justify-content-between align-items-center">
                    <Col xs="12" sm="6">
                        <Button variant="link" href="https://www.facebook.com/yourLULAfamily/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook fa-fw fa-2x text-light" />
                        </Button>
                        <Button variant="link" href="https://www.instagram.com/yourlulafamily/?hl=en" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram fa-fw fa-2x text-light" />
                        </Button>
                    </Col>

                    <Col xs="12" sm="6" className="text-sm-right">
                        &copy; {new Date().getFullYear()} Level Up Learning Academy
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default App;
