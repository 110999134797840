import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import parentSessions from './_images/parent-sessions.jpeg'
import tuesdayCamps from './_images/tuesday-camps.jpeg'
import summerCamps from './_images/summer-camps.jpeg'
import summerCamp from './_images/summer-camp.jpeg'

function Announcement() {
    return (
        <div className="bg-green py-3">
            <Container className="text-center">
                <h3 className="text-white">
                    <i className="fas fa-umbrella-beach fa-fw text-yellow mr-2" />
                    Brand New Summer Offerings!
                </h3>

                <hr className="border-white" />

                <Row className="align-items-center">
                    <Col xs="12" sm="6" lg="3" className="my-2">
                        <a href={parentSessions} target="_blank" className="text-white" rel="noopener noreferrer">
                            Join our FREE Parent & Educator Summer Series
                            <i className="ml-1 fas fa-long-arrow-alt-right fa-fw" />
                        </a>
                    </Col>
                    <Col xs="12" sm="6" lg="3" className="my-2">
                        <a href={tuesdayCamps} target="_blank" className="text-white" rel="noopener noreferrer">
                          Tuesday Summer Camps! Awesome for middle and high school.
                          <i className="ml-1 fas fa-long-arrow-alt-right fa-fw" />
                      </a>
                    </Col>
                    <Col xs="12" sm="6" lg="3" className="my-2">
                        <a href={summerCamps} target="_blank" className="text-white" rel="noopener noreferrer">
                          Summer Camp 2021! All virtual, all the time!.
                          <i className="ml-1 fas fa-long-arrow-alt-right fa-fw" />
                      </a>
                    </Col>
                    <Col xs="12" sm="6" lg="3" className="my-2">
                        <a href={summerCamp} target="_blank" className="text-white" rel="noopener noreferrer">
                          MWF Camp, Tuesday Evening Camp, Free Parent Sessions!
                          <i className="ml-1 fas fa-long-arrow-alt-right fa-fw" />
                      </a>
                    </Col>
                </Row>

                <hr className="border-white"/>

                <h4>
                    <a href="https://forms.gle/7cx2ahANm2NSMshn9" target="_blank" className="text-white" rel="noopener noreferrer">
                        <i className="fas fa-pen-alt fa-fw text-yellow mr-2" />
                        Sign up for one of our LULA Summer Camp sessions today!
                    </a>
                </h4>
            </Container>
        </div>
    );
}

export default Announcement;
