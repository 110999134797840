import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Announcement from './Announcement'
import Navigation from './Navigation';
import Home from './Home';
import Testimonials from './Testimonials';
import FAQs from './FAQs';
import Footer from './Footer';
import './App.scss';

function App() {
    return (
        <Router>
            <Announcement />

            <Navigation />

            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/testimonials" exact component={Testimonials} />
                <Route path="/faqs" exact component={FAQs} />
            </Switch>

            <Footer />
        </Router>
    );
}

export default App;
